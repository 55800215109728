import { PublicKey } from '@solana/web3.js';
import bs58 from 'bs58';

export interface WalletAuthSignature {
  walletAddress: string;
  signature: string;
}
export const generateWalletAuthSignature = async ({
  publicKey,
  signMessage,
}: {
  publicKey: PublicKey | null;
  signMessage: ((message: Uint8Array) => Promise<Uint8Array>) | undefined;
}): Promise<WalletAuthSignature> => {
  if (!publicKey || !signMessage) {
    throw new Error('Wallet not connected.');
  }

  const walletAddress = publicKey.toBase58();
  const encodedPublicKey = new TextEncoder().encode(walletAddress);
  const signedMessage = await signMessage(encodedPublicKey);
  const signature = bs58.encode(signedMessage);

  return {
    walletAddress,
    signature,
  };
};
