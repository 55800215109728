import React from 'react';
import classnames from 'classnames';
import { Icon } from '..';

interface CardButtonProps {
  onClick: () => void;
  text: string;
  disabled?: boolean;
  rightImage: string | React.FC<React.SVGProps<SVGElement>>;
}
const CardButton = ({
  onClick,
  text,
  disabled,
  rightImage,
}: CardButtonProps) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={classnames(
        'rounded-md border w-full border-solid border-primaryGray-400 py-3 px-4 flex justify-between',
        'items-center hover:bg-primaryGray-300 disabled:bg-grayAsh-700',
      )}
    >
      <p className={disabled ? 'text-grayAsh-400' : 'text-primaryBlack-400'}>
        {text}
      </p>
      <Icon src={rightImage} width="8" height="8" />
    </button>
  );
};

export default CardButton;
