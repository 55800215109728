import classnames from 'classnames';
import React from 'react';
import { Icon } from 'src/components/Icon';
import { FontFamily, FontWeight, Size } from 'src/utils/constants';
import { ButtonTheme } from './theme';

export type ButtonVariant =
  | 'primary'
  | 'primary-yellow'
  | 'blue'
  | 'ghost'
  | 'gray-ghost'
  | 'danger'
  | 'danger-ghost'
  | 'white'
  | 'white-danger'
  | 'transparent-danger'
  | 'success'
  | 'warning'
  | 'transparent'
  | 'gradient'
  | 'link';

export interface ButtonProps {
  text?: string;
  iconLeft?: string | React.FC<React.SVGProps<SVGElement>>;
  iconRight?: string | React.FC<React.SVGProps<SVGElement>>;
  iconRightColor?: string;
  onClick?: () => any;
  variant?: ButtonVariant;
  size?: Size | 'xl';
  fontFamily?: FontFamily;
  fontWeight?: FontWeight;

  disabled?: boolean;
  fullWidth?: boolean;
  id?: string;
  type?: 'button' | 'submit';
  form?: string;
  roundedFull?: boolean;
  dataTestId?: string;
  xPadding?: string | number;
}

const Button = ({
  text,
  onClick,
  iconLeft,
  iconRight,
  variant = 'primary',
  fontFamily = 'montserrat',
  fontWeight = 'semibold',
  iconRightColor = 'currentColor',
  size = 'md',
  fullWidth,
  disabled = false,
  id,
  roundedFull = false,
  type = 'button',
  xPadding = '6',
  dataTestId,
}: ButtonProps) => (
  <button
    id={id}
    disabled={disabled}
    type={type}
    data-testid={dataTestId}
    className={classnames(
      'disabled:cursor-default flex justify-center items-center',
      ButtonTheme.variant[variant],
      ButtonTheme.size[size],
      !text && 'px-2',
      text && `px-${xPadding}`,
      `font-${fontWeight}`,
      `text-${fontFamily}`,
      {
        'rounded-full': roundedFull,
        'rounded-xl': size !== 'sm' && !roundedFull,
        'rounded-md': size === 'sm' && !roundedFull,
        'w-full': fullWidth,
      },
    )}
    onClick={type !== 'submit' ? onClick : undefined}
  >
    {iconLeft && (
      <div
        className={classnames({
          'mr-1.5': text,
        })}
      >
        <Icon
          fill="currentColor"
          src={iconLeft}
          width={ButtonTheme.iconSize[size].width}
          height={ButtonTheme.iconSize[size].height}
        />
      </div>
    )}
    {text}
    {iconRight && (
      <div
        className={classnames({
          'ml-1.5': text,
        })}
      >
        <Icon
          fill={iconRightColor}
          src={iconRight}
          width={ButtonTheme.iconSize[size].width}
          height={ButtonTheme.iconSize[size].height}
        />
      </div>
    )}
  </button>
);

export default Button;
