import { PublicKey } from '@solana/web3.js';

export const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
  'ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL',
);
export const BASE_TOKEN_NAME = 'SOL';
export const MAX_DECIMALS = 6;
export const MESSAGE_FEE = 5; // in dollars
export const DEFAULT_SYMBOL = 'MNB';
export const COLLECTION_PUBKEY_INDEX = 1; // temporary until backend parses createFollowCollection transaction signature
export const DEFAULT_MAX_SUPPLY = 10000;
export const DEFAULT_COMMUNITY_MINT_SHARE = 0; // in basis points
export const DEFAULT_COMMUNITY_ROYALTY_SHARE = 0;
export const DEFAULT_ROYALTY = 1000; // in basis points
export const DEFAULT_BASE_TOKEN_MULTIPLIER = 1000000;
export const DEFAULT_CREATOR_AIRDROP_SHARE = 0;
export const ITEM_URI_IMAGE = 'https://moonbase.com/nft-image.png';
export const BASIS_POINTS_MULTIPLIER = 100;
export const MAINNET_USDC_MINT = new PublicKey(
  'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
);
export const CREATE_COLLECTION_FEE = 0.02;
export const WALLET_LEARN_MORE_URL =
  'https://www.notion.so/harmonicai/Moonbase-FAQ-ce4803c3b37147b1a88a9a1874ed9a22#c170701aaa8e4eea830e99dc20837db2';
