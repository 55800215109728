function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import regeneratorRuntime from "/vercel/path0/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
export var fetchWithAuth = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(url, init) {
        var ref, firebaseAuth, token, ref1, fetchResult, result, responseError, error;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    ;
                    if ("object" === "undefined") {
                        console.error("Error cannot use client fetch in ssr mode.");
                    }
                    firebaseAuth = getAuth(getApp());
                    _ctx.next = 5;
                    return (ref = firebaseAuth.currentUser) === null || ref === void 0 ? void 0 : ref.getIdToken();
                case 5:
                    token = _ctx.sent;
                    ;
                    _ctx.next = 9;
                    return fetch(url, _objectSpread({}, init, {
                        headers: _objectSpread({}, (ref1 = init === null || init === void 0 ? void 0 : init.headers) !== null && ref1 !== void 0 ? ref1 : {}, {
                            authorization: token !== null && token !== void 0 ? token : ""
                        })
                    }));
                case 9:
                    fetchResult = _ctx.sent;
                    _ctx.next = 12;
                    return fetchResult.json();
                case 12:
                    result = _ctx.sent;
                    if (!fetchResult.ok) {
                        _ctx.next = 15;
                        break;
                    }
                    return _ctx.abrupt("return", result);
                case 15:
                    responseError = {
                        type: "Error",
                        message: result.message || "Something went wrong",
                        data: result.data || "",
                        code: result.code || ""
                    };
                    error = new Error();
                    error = _objectSpread({}, error, responseError);
                    throw error;
                case 19:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function fetchWithAuth(url, init) {
        return _ref.apply(this, arguments);
    };
}();
