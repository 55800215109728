import { useTreatments } from "@splitsoftware/splitio-react";
export var SPLITS;
(function(SPLITS) {
    SPLITS["showSearch"] = "show-search";
    SPLITS["enableWeb3Transactions"] = "enable-web3-transactions";
    SPLITS["enableMaintenanceMode"] = "enable-maintenance-mode";
    SPLITS["enableAirdropFeature"] = "enable-airdrop-feature";
    SPLITS["enableClaimsFeature"] = "enable-claim-feature";
})(SPLITS || (SPLITS = {}));
var useFlags = function(flagName) {
    /* `useTreatments` returns the evaluated treatments of the given list of split names.
   * While the SDK is not ready, treatments values are `control`. */ var treatments = useTreatments([
        flagName
    ]);
    var treatment = treatments[flagName].treatment;
    var enabled = treatment === "on";
    return {
        enabled: enabled
    };
};
export default useFlags;
