function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import logger from "client/services/client-logger";
export var AuthTypes;
(function(AuthTypes) {
    AuthTypes["EMAIL"] = "email";
    AuthTypes["GOOGLE"] = "google";
    AuthTypes["LINKEDIN"] = "linkedin";
    AuthTypes["WALLET"] = "wallet";
})(AuthTypes || (AuthTypes = {}));
export var trackSignUp = function(type, supportingData) {
    trackEvent("sign_up", {
        event_category: "engagement",
        event_label: type
    }, supportingData);
};
export var trackLinkWallet = function(type, supportingData) {
    trackEvent("link_wallet", {
        event_category: "crypto",
        event_label: type
    }, supportingData);
};
export var trackTokenMinted = function(type, supportingData) {
    trackEvent("token_minted", {
        event_category: "crypto",
        event_label: type
    }, supportingData);
};
export var trackGenerateInviteCode = function(supportingData) {
    trackEvent("invite_code_generated", {
        event_category: "engagement",
        event_label: "invite code"
    }, supportingData);
};
export var trackSocialShare = function(supportingData) {
    trackEvent("share", {
        event_category: "engagement",
        event_label: "platform"
    }, supportingData);
};
export var trackConnectSocial = function(supportingData) {
    trackEvent("join_group", {
        event_category: "engagement",
        event_label: "platform"
    }, supportingData);
};
// log specific events happening. pagesviews happen automatically.
export var trackEvent = function(action, eventData, supportingData) {
    try {
        window.gtag("event", action, _objectSpread({}, eventData, supportingData));
    } catch (err) {
        logger.error("GA Tracking Error", err);
    }
};
